import React from 'react';
import Layout from '../components/layout';
import Logo from '../images/circle-cropped.png';
export default () => (
  <Layout>
    <div className="aboutWrapper">
      <div className="headerAbout">
        {' '}
        EZ Sizing is a SaaS, coming soon to help you with one stop shop for eCommerce
      </div>
      <div className="innerCircle">
        <img src={Logo} className="icon" />
      </div>
    </div>
    <div className="rights"> 2020 © All Rights Reserved.</div>
  </Layout>
);
